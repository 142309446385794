import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CommonModal from "@/computed/CommonModal";
import { message } from 'antd';
import { useRef } from 'react';
import httpRequest from "@/utils/httpRequest";
import {useDispatch, useSelector} from "react-redux";
import './ImageModal.css'; // 样式文件

const ImageModal = forwardRef((props, ref) => {
    const { onCancel } = props;
    const userInfo = useSelector((state) => state.user.userInfo);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]); // 图片列表
    const [selectedImage, setSelectedImage] = useState(null); // 选中的图片
    const modalRef = useRef(null);
    const dispatch = useDispatch();

    // 暴露 openModal 方法
    useImperativeHandle(ref, () => ({
        openModal: () => {
            modalRef.current?.open();
            setSelectedImage(null); // 打开弹框时重置选中状态
        },
    }));

    // 获取图片列表
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const res = await httpRequest.get('/img/list');
                console.log(res)
                if (res.code === 200) {
                    setImages(res.list);
                } else {
                    message.warning(res.msg);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchImages();
    }, []);

    // 图片点击事件
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    // 确认按钮点击事件
    const handleSubmit = async () => {
        if (!selectedImage) {
            message.warning('请选择图片');
            return;
        }

        setLoading(true);
        try {
            const params = {
                id: userInfo.id, // 用户 ID
                head_photo: selectedImage.url, // 选中图片的 URL
            };
            const res = await httpRequest.post('/users/head', params);
            if (res.code === 200) {
                message.success('修改头像成功');
                // 更新 Redux 中的 userInfo
                dispatch.user.setUserInfo({
                    ...userInfo, // 保留其他用户信息
                    head_photo: selectedImage.url, // 更新头像字段
                });
                modalRef.current?.close();
            } else {
                message.warning(res.msg);
            }
        } catch (error) {
            console.error(error);
            message.error('修改头像失败');
        } finally {
            setLoading(false);
        }
    };

    return (
        <CommonModal
            ref={modalRef}
            title="选择头像"
            loading={loading}
            onCancel={onCancel}
            onOk={handleSubmit}
        >
            <div className="image-list">
                {images.map((image) => (
                    <div
                        key={image.id}
                        className={`image-item ${selectedImage?.id === image.id ? 'active' : ''}`}
                        onClick={() => handleImageClick(image)}
                    >
                        <img src={image.url} alt={image.name} />
                    </div>
                ))}
            </div>
        </CommonModal>
    );
});

export default ImageModal;
