import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';
import App from './App';
import  store from './store';
import './index.css'


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>
);

