// src/router/HomeView.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from '@/views/MainLayout';
import NotFound from '@/views/NotFound';
import routes from './config.js'
const _import = file => require('../views/' + file + '.js').default
for (let i = 0; i < routes.length; i++) {
    routes[i].component = _import(routes[i].url)
}
function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={  route.path}
                        element={<route.component />}
                    />
                ))}
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default AppRoutes;


