// src/pages/TopicView/index.js
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Select, Collapse, Tag, Empty, Pagination, message } from 'antd';
import httpRequest from '@/utils/httpRequest';
import { useSelector } from 'react-redux';
import './TopicView.scss';

const { Option } = Select;

export default function TopicView() {
    // 用户权限状态
    const userInfo = useSelector(state => state.user.userInfo);
    const hasPermission = userInfo?.role_id <= 2;

    // 组件状态管理
    const [typeList, setTypeList] = useState([]);
    const [topicList, setTopicList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [selectedType, setSelectedType] = useState();

    // 获取题目类型
    const fetchTypeList = useCallback(async () => {
        try {
            const res = await httpRequest.get('/type');
            setTypeList(res.list);
        } catch (error) {
            console.error('获取类型失败:', error);
            message.error('题目类型获取失败，请稍后重试');
        }
    }, []);

    // 获取题目列表
    const fetchTopics = useCallback(async () => {
        if (!hasPermission) return;
        try {
            const params = {
                type: selectedType,
                page: currentPage,
                limit: 10
            };
            const res = await httpRequest.get('/topic', params);
            setTopicList(res.data.list);
            setTotal(res.data.total);
        } catch (error) {
            console.error('获取题目失败:', error);
            message.error('题目列表获取失败，请稍后重试');
        }
    }, [hasPermission, selectedType, currentPage]);

    // 数据获取副作用
    useEffect(() => {
        if (hasPermission) {
            fetchTypeList();
            fetchTopics();
        }
    }, [hasPermission, fetchTypeList, fetchTopics]);

    // 分页处理
    const handlePageChange = useCallback(page => setCurrentPage(page), []);

    // 折叠面板数据生成
    const panels = useMemo(
        () => topicList.map(item => ({
            key: item.id,
            label: (
                <div className="panel-title">
                    <span>{item.title}</span>
                    <Tag color="warning">{item.importance}</Tag>
                </div>
            ),
            children: (
                <>
                    <div className="keywords">
                        {item.keyword.split(',').map(tag => (
                            <Tag key={tag} className="keyword-tag">{tag}</Tag>
                        ))}
                    </div>
                    <div className="result">{item.result}</div>
                </>
            )
        })),
        [topicList]
    );

    if (!hasPermission) {
        return <Empty description="您未登录或权限不足" className="auth-empty" />;
    }

    return (
        <div className="topic-view">
            <div className="operation-header">
                <div className="search-section">
                    <Select
                        placeholder="请选择题型"
                        allowClear
                        value={selectedType}
                        onChange={setSelectedType}
                        className="type-selector"
                    >
                        {typeList.map(item => (
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="topic-list">
                {topicList.length > 0 ? (
                    <>
                        <Collapse accordion items={panels} className="topic-collapse" />
                        <div className="pagination-wrapper">
                            <Pagination
                                current={currentPage}
                                total={total}
                                pageSize={10}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                showTotal={total => `共 ${total} 题`}
                                className="custom-pagination"
                            />
                        </div>
                    </>
                ) : (
                    <Empty description="暂无题目数据" className="data-empty" />
                )}
            </div>
        </div>
    );
}