import React, { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import {Select, Input, Button, Row, Col, Card, Tag, Dropdown, Spin} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import httpRequest from '@/utils/httpRequest'; // 引入封装好的 httpRequest
import './LearnView.scss'; // 样式文件
const { Option } = Select;
const LearnPage = () => {
    const token = useSelector((state) => state.user.token); // 从 state.user.token 获取 token
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({ type: 1, key: '' });
    const [dataList, setDataList] = useState([]);
    const [options, setOptions] = useState([]);
    const containerHeight = useSelector(state => state.user.containerHeight);
    // 获取类型列表
    const fetchTypeList = async () => {
        setLoading(true);
        try {
            const res = await httpRequest.get('/type'); // 使用封装好的 httpRequest
            console.log(res);
            if (res.code === 200) {
                setOptions(res.list);
            }
        } catch (error) {
            console.error('获取类型列表失败:', error);
        }
    };

    // 获取数据列表
    const fetchDataList = async (params) => {
        setLoading(true);
        try {
            const res = await httpRequest.get('/learn', params); // 使用封装好的 httpRequest
            console.log(res);
            if (res.code === 200) {
                let list = res.data.list.map(item=>{
                    item.learn_more =  item.learn_more.map(t=>{
                        t.key = t.id
                        t.label = t.title
                        return t;
                    })
                    return item
                })
                console.log(list)
                setDataList(list);
            }
        } catch (error) {
            console.error('获取数据列表失败:', error);
        } finally {
            setLoading(false);
        }
    };

    // 初始化加载类型列表和数据列表
    useEffect(() => {
        fetchTypeList();
        fetchDataList({ page: 1, limit: 99999, ...form });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.type]);
    // 搜索按钮点击事件
    const handleSearch = () => {
        fetchDataList({ page: 1, limit: 99999, ...form });
    };


    // 根据类型 ID 获取类型名称
    const getOptionsNameById = (typeId) => {
        const option = options.find((item) => item.id === typeId);
        return option ? option.name : '未知类型';
    };


    const handleMenuClick = (e) => {
        window.open(e.item.props.url, '_blank');
    };

    // 渲染下拉菜单
    const renderMenu = t => {
        // 确保 items 是一个数组，并且每个元素都包含 id 和 url
        if (!Array.isArray(t) || t.length === 0) {
            return []; // 返回一个空的 Menu 组件
        }
        return {
            items:t.map((item) => {
                item.key=item.id
                item.label=item.title
                item['data-url']=item.url
                return item;
            }),
            onClick: handleMenuClick,
        }
    };
    return (
        <div className="learn-page">
            {token ? (
                <>
                    <div className="learn-header">
                        <Select
                            style={{ width: 200, marginRight: 16 }}
                            placeholder="学习种类"
                            allowClear
                            value={form.type}
                            onChange={(value) => setForm({ ...form, type: value })}
                        >
                            {options.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                        <Input
                            placeholder="名称"
                            allowClear
                            value={form.key}
                            onChange={(e) => setForm({ ...form, key: e.target.value })}
                            style={{ width: 200, marginRight: 16 }}
                        />
                        <Button type="primary" onClick={handleSearch}>
                            搜索
                        </Button>
                    </div>
                    <div className="main">
                        <Spin spinning={loading} tip="拼命加载中">
                            <Card
                                style={{
                                    maxHeight: containerHeight+'px',         // 固定容器高度
                                    overflowY: 'auto',       // 垂直溢出时显示滚动条
                                    border: '1px solid #f0f0f0' // 边框（可选）
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    {dataList.map((item, index) => (
                                        <Col key={index} span={8}>
                                            <Card
                                                className='card'
                                                title={
                                                    <div>
                                                        {item.sort}. {item.name}{' '}
                                                        <Tag color="geekblue">{getOptionsNameById(item.type)}</Tag>
                                                    </div>
                                                }
                                                extra={<Button type="link">查看详情</Button>}
                                            >
                                                <p>{item.description}</p>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
                                                    <Dropdown menu={renderMenu(item.learn_more)}>
                                                        <Button disabled={!item.learn_more || item.learn_more.length === 0}>
                                                            知识拓展 <DownOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Spin>
                    </div>
                </>
            ) : (
                <div className="empty">请先登录</div>
            )}
        </div>
    );
};

export default LearnPage;
