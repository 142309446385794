// src/layouts/MainLayout.js
import React from 'react';
import {Layout, Menu } from 'antd';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import routes from '@/router/config';
import './main.css'

import HeaderRight from "@/views/HeaderRight";
const { Header, Content } = Layout;
function MainLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    // 获取当前路径对应的菜单项
    const selectedKeys = [location.pathname];
    const menuClick = e=>{
        navigate(e.key)
    }
    return (
        <div className='main-box'>
            <Layout className='layout' >
                <Header className='header'>
                    <Menu
                        className="menu"
                        theme="light"
                        mode="horizontal"
                        selectedKeys={selectedKeys}
                        onClick={menuClick}
                        items={routes.map(route => ({
                            key: route.path,
                            label: route.meta.title,
                            icon: route.icon, // 如果有图标可以添加
                        }))}
                    />
                    <HeaderRight></HeaderRight>
                </Header>
                <Content className='content'>
                    <Outlet/>
                </Content>
            </Layout>
            <p className={'footer'}>湘ICP备19018386号</p>
        </div>
    );
}

export default MainLayout;
