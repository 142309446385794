import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CommonModal from "@/computed/CommonModal";
import { Form, Input, message } from 'antd';
import { useRef } from 'react';
import md5 from 'js-md5';
import httpRequest from "@/utils/httpRequest";
import { useDispatch, useSelector } from "react-redux";

const RegisterModal = forwardRef((props, ref) => {
    const { onCancel } = props;
    const dispatch = useDispatch();
    const [isUpdate, setIsUpdate] = useState(false);
    const userInfo = useSelector((state) => state.user.userInfo);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);

    // 初始化表单数据
    useEffect(() => {
        if (userInfo && userInfo.id) {
            setIsUpdate(true);
            form.setFieldsValue(userInfo);
        } else {
            setIsUpdate(false);
            form.resetFields(); // 清空表单
        }
    }, [form, userInfo]);

    // 暴露 openModal 方法
    useImperativeHandle(ref, () => ({
        openModal: () => {
            modalRef.current?.open();
        },
    }));

    // 提交表单
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            let params = {
                role_id: 3,
                ...userInfo,
                ...values,
            };

            // 如果是注册操作，处理密码字段
            if (!isUpdate) {
                params.passcode = md5(values.password?.toString() || '');
            } else {
                delete params.password; // 更新模式下移除密码字段
            }

            const api = isUpdate ? '/users/update' : '/register';
            setLoading(true);
            try {
                const res = await httpRequest.post(api, params);
                if (res.code === 200) {
                    dispatch.user.setUserInfo(res.userInfo);
                    message.success(res.msg);
                    modalRef.current?.close();
                } else {
                    message.warning(res.msg);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error('表单验证失败:', err);
        }
    };

    // 表单字段配置
    const formItemList = [
        {
            label: "用户名",
            name: "role_name",
            rules: [{ required: true, message: '请输入用户名' }],
            disabled: isUpdate,
        },
        {
            label: "用户代码",
            name: "user_code",
            rules: [],
            disabled: isUpdate,
        },
        {
            label: "账号",
            name: "user_name",
            rules: [
                { required: true, message: '请输入账号' },
                { pattern: /^[a-zA-Z]\w{7,15}$/, message: '字母开头,由数字字母下划线组成,长度8-16位' },
            ],
            disabled: isUpdate,
        },
        {
            label: "密码",
            name: "password",
            rules: [
                { required: !isUpdate, message: '请输入密码' },
                { pattern: /^[a-zA-Z]\w{7,15}$/, message: '字母开头,由数字字母下划线组成,长度8-16位' },
            ],
            hidden: isUpdate, // 更新模式下隐藏
        },
        {
            label: "手机",
            name: "phone",
            rules: [
                { required: true, message: '请输入手机号码' },
                { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' },
            ],
        },
        {
            label: "邮箱",
            name: "email",
            rules: [
                { required: true, message: '请输入邮箱' },
                { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱' },
            ],
        },
        {
            label: "QQ",
            name: "qq",
            rules: [
                { required: true, message: '请输入QQ' },
                { pattern: /^[1-9][0-9]{4,9}$/gim, message: '请输入正确的QQ号' },
            ],
        },
        {
            label: "真实姓名",
            name: "real_name",
            rules: [{ required: true, message: '请输入真实姓名' }],
        },
        {
            label: "备注",
            name: "note",
            rules: [],
        },
    ];

    return (
        <CommonModal
            ref={modalRef}
            title={isUpdate ? '修改' : '注册'}
            loading={loading}
            onCancel={onCancel}
            onOk={handleSubmit}
        >
            <Form form={form} labelCol={{ span: 4 }}>
                {formItemList.map((item) => {
                    if (item.hidden) return null;
                    return (
                        <Form.Item
                            label={item.label}
                            name={item.name}
                            rules={item.rules}
                            key={item.name}
                        >
                            <Input placeholder={`请输入${item.label}`} disabled={item.disabled} />
                        </Form.Item>
                    );
                })}
            </Form>
        </CommonModal>
    );
});

export default RegisterModal;
