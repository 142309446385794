import { init } from '@rematch/core';
import user from './models/userModel';

// 创建 Rematch Store
const store = init({
    models: {
        user,
    },
});

export default store;
