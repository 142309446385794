//eslint-disable-next-line
export default {
    state: {
        token: sessionStorage.getItem("token")||'',
        userInfo: sessionStorage.getItem("userInfo")?JSON.parse(sessionStorage.getItem("userInfo")):{},
        containerHeight:500
    },
    reducers: {
        setToken: (state, payload) => {
            sessionStorage.setItem("token",payload);
            return {
                ...state,
                token: payload,
            }
        },
        setUserInfo: (state, payload) => {
            console.log('payload',payload)
            sessionStorage.setItem("userInfo", JSON.stringify(payload));
            return {
                ...state,
                userInfo: payload,
            }
        }
    },
};
