import React, {useEffect, useState} from 'react';
import {Card, Carousel, Col, Row} from 'antd';
import httpRequest from "@/utils/httpRequest";
import './HomeView.scss'
import img from '../../assets/image/wz.png'



const HomeView = () => {
    // 轮播图
    const [bannerList, setBannerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const getBannerList = async () => {
        setLoading(true);
        try {
            const res = await httpRequest.get('/banner');
            setBannerList(res.list);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    // 热搜
    const [articleList, setArticleList] = useState([]);
    const getArticleList = async () => {
        setLoading(true);
        try {
            const res = await httpRequest.get('/hot');
            setArticleList(res.list);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    // 每日一练
    const dailyList = [
        {
            id:1,
            title:'每日一练',
            content:'每天进步一点点,早日成为大佬级',
            img:'http://hnclove.com/img/day1.8402f4e7.jpeg'
        },
        {
            id:2,
            title:'每日一练',
            content:'每天进步一点点,早日成为大佬级',
            img:'http://hnclove.com/img/day1.8402f4e7.jpeg'
        },
        {
            id:3,
            title:'每日一练',
            content:'每天进步一点点,早日成为大佬级',
            img:'http://hnclove.com/img/day1.8402f4e7.jpeg'
        }
    ]

    useEffect(() => {
        getBannerList();
        getArticleList();
    }, []);

    return (
        <Row className='home-view' gutter={16}>
            <Col span={18}>
                <Carousel autoplay arrows className="carousel">
                    {loading ? <div>Loading...</div> : bannerList.map((item,index) =>
                        <div
                            key={item.id}
                            className={'carousel-item'}
                        >
                            <a href={item.target}>
                                <img src={item.url} alt=""/>
                            </a>
                            {item.name}
                        </div>)}
                </Carousel>
            </Col>
            <Col span={6}>
                <Card
                    size="small"
                    title={
                        <div className="r-title">
                            <img src={img} alt="" width="20"/>
                            <span>文章榜</span>
                        </div>
                    }
                    extra={<a href="https://www.baidu.com/" target="_blank"
                              rel="noopener noreferrer">More</a>}
                    className='article card'
                >
                    {loading ? <div>Loading...</div> : articleList.map(item =>
                        <div
                            key={item.hotIndex}
                            className='article-item'
                        >
                            <span className='num'>{item.rank}</span>
                            <p>
                                <a href={'https://www.baidu.com/s?wd=' + item.title}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   className='title text'>{item.title}
                                </a>
                            </p>
                        </div>)}
                </Card>
            </Col>

            {dailyList.map((item,index) =>
                <Col span={8} key={index}>
                    <Card
                        size="small"
                        className='daily card'
                    >
                        <img src={item.img} alt=""/>
                        <div className="right">
                            <p className="title">{item.title}</p>
                            <p className="r-content">{item.content}</p>
                        </div>
                    </Card>
                </Col>
            )}
        </Row>
    )
        ;
};

export default HomeView;