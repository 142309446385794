import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Pagination, Tooltip, Empty } from 'antd';
import { SearchOutlined, LinkOutlined } from '@ant-design/icons';
import httpRequest from '@/utils/httpRequest';
import './TechnologyView.scss'
import  defaultLogo from '../../assets/image/img.png'

const { Option } = Select;

const TechnologyPage = () => {
    // 组件状态管理
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        dataList: [],
        pagination: { page: 1, limit: 9, total: 0 },
        options: {
            linkTypes: [],
            langTypes: []
        }
    });

        // page:this.page,
        // limit:9,
        // link_type:this.form.link_type,
        // type:this.form.type,
        // key:this.form.key

    // 生命周期处理
    useEffect(() => {
        const init = async () => {
            await fetchOptions();
            fetchData();
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // API 请求封装
    const fetchData = async (extraParams = {}) => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            const params =  {
                ...values,
                ...state.pagination,
                ...extraParams
            }
            console.log(params)
            const data = await httpRequest.get('/link', params);
            console.log(666,data)
            if (data?.code === 200) {
                setState(prev => ({
                    ...prev,
                    dataList: data.data.list,
                    pagination: { ...prev.pagination, total: data.data.total }
                }));
            }
        } catch (error) {
            console.error('数据加载失败:', error);
        } finally {
            setLoading(false);
        }
    };

    // 选项加载
    const fetchOptions = async () => {
        try {
            const [linkRes, langRes] = await Promise.all([
                httpRequest.get('/link/type'),
                httpRequest.get('/type')
            ]);
            console.log('linkRes',linkRes)
            console.log('langRes',langRes)
            setState(prev => ({
                ...prev,
                options: {
                    linkTypes: linkRes.list || [],
                    langTypes: langRes.list || []
                }
            }));
        } catch (error) {
            console.error('选项加载失败:', error);
        }
    };

    // 事件处理
    const handleSearch = () => {
        setState(prev => ({
            ...prev,
            pagination: { ...prev.pagination, current: 1 }
        }));
        fetchData({ page: 1 });
    };

    const handlePageChange = (page) => {
        setState(prev => ({ ...prev, pagination: { ...prev.pagination, page: page } }));
        fetchData({ page });
    };

    const handleCardClick = (url) => {
        const BASE_URL = 'http://image.hnclove.com/zy/#/'
        if(url.startsWith('http')){
            window.open(url, '_blank', 'noopener,noreferrer');
        }else{
            window.open(BASE_URL+url, '_blank', 'noopener,noreferrer');
        }
    };

    // 渲染工具函数
    const renderSelectOptions = (items) =>
        items.map(item => (
            <Option key={item.id} value={item.id}>
                {item.name}
            </Option>
        ));

    const renderCardList = () => {
        if (state.dataList.length === 0) {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="data-empty" />;
        }
        return state.dataList.map(item => (
            <Col key={item.id} span={8}>
                <Card
                    hoverable
                    className="tech-card"
                    onClick={() => handleCardClick(item.url)}
                >
                    <div className="card-header">
                        <div className="card-title">
                            <img
                                src={item.logo_url || defaultLogo}
                                alt={item.title}
                                style={{ width: item.logoWidth || '30px' }}
                                onError={(e) => {
                                    // 双重保险：如果默认图片也不存在，使用透明像素占位
                                    e.target.src = defaultLogo;
                                    e.target.onerror = null; // 防止死循环
                                }}
                            />
                            <Tooltip title={item.title}>
                                <span className="title-text">{item.title}</span>
                            </Tooltip>
                        </div>
                        <Button
                            type="link"
                            icon={<LinkOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCardClick(item.url);
                            }}
                        >
                            详情
                        </Button>
                    </div>

                    <Tooltip title={item.description}>
                        <div className="card-content">
                            {item.description || '暂无描述'}
                        </div>
                    </Tooltip>
                </Card>
            </Col>

        ));
    };

    return (
        <div
            className="technology-container"
            style={{ height: '300px' }}
        >
            {/* 搜索表单 */}
            <Form form={form} layout="inline" className="search-form">
                <Form.Item name="link_type">
                    <Select
                        placeholder="链接类型"
                        allowClear
                        style={{ width: 160 }}
                        loading={!state.options.linkTypes.length}
                    >
                        {renderSelectOptions(state.options.linkTypes)}
                    </Select>
                </Form.Item>

                <Form.Item name="type">
                    <Select
                        placeholder="语言类型"
                        allowClear
                        style={{ width: 160 }}
                        loading={!state.options.langTypes.length}
                    >
                        {renderSelectOptions(state.options.langTypes)}
                    </Select>
                </Form.Item>

                <Form.Item name="key">
                    <Input
                        placeholder="搜索关键词"
                        allowClear
                        style={{ width: 200 }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={handleSearch}
                        loading={loading}
                    >
                        搜索
                    </Button>
                </Form.Item>
            </Form>

            {/* 数据列表 */}
            <Row gutter={[20, 20]} className="data-list">
                {renderCardList()}
            </Row>

            {/* 分页器 */}
            {state.dataList.length > 0 && (
                <Pagination
                    className="data-pagination"
                    current={state.pagination.page}
                    total={state.pagination.total}
                    pageSize={state.pagination.limit}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    showTotal={total => `共 ${total} 条`}
                />
            )}
        </div>
    );
};



export default TechnologyPage;
