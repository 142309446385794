// src/hooks/useModalControl.js
import { useState, useRef, useEffect } from 'react';

export const useModalControl = (ModalComponent) => {
    const [visible, setVisible] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        if (visible && modalRef.current) {
            modalRef.current?.openModal();
        }
    }, [visible]);

    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const modal = visible && (
        <ModalComponent
            ref={modalRef}
            onCancel={hide}
        />
    );

    return { modal, show, hide };
};