import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import CommonModal from "@/computed/CommonModal";
import {Form, Input, Checkbox, Flex, message} from 'antd';
import {useRef} from 'react';
import  md5 from 'js-md5'
import httpRequest from "@/utils/httpRequest";
import {Cookies, decryptBy, encryptBy} from "@/utils";
import {useDispatch} from "react-redux";
const RegisterModel = forwardRef((props, ref) => {
    const {
        onCancel,
    } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    useImperativeHandle(ref, () => ({
        openModal: () => {
            modalRef.current?.open()
        }
    }));

    const dispatch = useDispatch();
    // 表单数据回填
    useEffect(() => {
        let loginMsg = JSON.parse(decryptBy(Cookies.getItem('loginMsg')) || '{}');
        if (loginMsg.remember) {
            form.setFieldsValue(loginMsg); // ✅ 动态设置表单值
        }
    }, [form]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            let params = {
                password: md5(values.password),
                user_name: values.user_name,
            }
            setLoading(true);
            try {
                const res = await httpRequest.post('/login',params);
                if(res.code===200){
                    message.success('登录成功')
                    console.log('登录成功',res)
                    modalRef.current?.close()
                    dispatch.user.setToken(res.data.token)
                    dispatch.user.setUserInfo(res.data.user)
                    Cookies.setItem('loginMsg',encryptBy(JSON.stringify(values)),30)
                }else{
                    message.error(res.msg)
                }
            } catch (error) {
                message.error(error)
            } finally {
                setLoading(false);
            }
            // 这里可以添加API请求
        } catch (err) {
            console.error('表单验证失败:', err);
        }
    };


    const formItemList = [
        {
            label:"账号",
            name:"user_name",
            rules: [{ required: true, message: '请输入账号' }]
        },
        {
            label:"密码",
            name:"password",
            type:'password',
            rules: [{ required: true, message: '请输入密码' }]
        }
    ]
    return (
        <CommonModal
            ref={modalRef}
            title="登录"
            loading={loading}
            onCancel={onCancel}
            onOk={handleSubmit}
        >
            <Form form={form}
                  labelCol={{
                      span: 4,
                  }}
                  wrapperCol={{
                      span: 20,
                  }}
                 >
                {
                    formItemList.map(item => {
                        return <Form.Item
                            label={item.label}
                            name={item.name}
                            rules={item.rules}
                            key={item.name}
                        >
                            {/*:disabled="disabled"*/}
                            <Input placeholder={item.item} type={item.type}/>
                        </Form.Item>
                    })
                }
                <Form.Item label={null}>
                    <Flex align="center">
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>记住密码</Checkbox>
                        </Form.Item>
                        <Form.Item name="isAuto" valuePropName="checked">
                            <Checkbox>自动登录</Checkbox>
                        </Form.Item>
                    </Flex>
                </Form.Item>


            </Form>
        </CommonModal>
    );
});

export default RegisterModel;