const arr = [
	{
		//首页 展示 轮播图 以及一些 每日一条土味情话  距离过年时间
		name: 'home',
		path: '/',
		url: 'global/HomeView',
		meta: {
			title: '首页'
		}
	},
	{
		//学习页面 里面分 vue  html+css   js   小程序  四个板块
		// 每个案例 里面  训练类型(type dom) 知识点 案例展示  案例介绍   知识点拓展网站
		name: 'learn',
		path: '/learn',
		url: 'global/LearnView',
		meta: {
			title: '学习案例'
		}
	},
	{
		//学习页面 里面分 vue  html+css   js   小程序  四个板块
		// 每个案例 里面  训练类型(type dom) 知识点 案例展示  案例介绍   知识点拓展网站
		name: 'topic',
		path: '/topic',
		url: 'global/TopicView',
		meta: {
			title: '面试题'
		}
	},
	// {
	// 	//学习页面 里面分 vue  html+css   js   小程序  四个板块
	// 	// 每个案例 里面  训练类型(type dom) 知识点 案例展示  案例介绍   知识点拓展网站
	// 	name: 'exam',
	// 	path: '/exam',
	// 	url: 'global/ExamView',
	// 	meta: {
	// 		title: '考试在线'
	// 	}
	// },
	{
		//分享一些技术的网站 外联
		name: 'technology',
		path: '/technology',
		url: 'global/TechnologyView',
		meta: {
			title: '技术分享'
		}
	},
	{
		name: 'message',
		path: '/message',
		url: 'global/MsgView',
		meta: {
			title: '留言板'
		}
	}
]

export default arr;