// src/layouts/MainLayout.js
import React from 'react';
import {Avatar, Button} from "antd";
import RegisterModal from "@/views/RegisterModal";
import LoginModal from "@/views/LoginModal";
import UpdatePasswordModal from "@/views/UpdatePassword";
import UpdatePhoto from "@/views/UpdatePhoto";

import {useAutoLogin} from "@/hooks/useAutoLogin";
import {useModalControl} from "@/hooks/useModalControl";
import './headerRight.css'

import {DownOutlined} from '@ant-design/icons';
import {Dropdown} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {Cookies} from "@/utils";

const HeaderRight = () => {
    const {isLoggedIn} = useAutoLogin();
    const {modal: loginModal, show: showLogin} = useModalControl(LoginModal);
    const {modal: registerModal, show: showRegister} = useModalControl(RegisterModal);
    const {modal: updateModal, show: showUpdate} = useModalControl(UpdatePasswordModal);
    const {modal: photoModal, show: showPhoto} = useModalControl(UpdatePhoto);

    // 下拉弹出框
    const userInfo = useSelector((state) => state.user.userInfo);
    const handleButtonClick = (e) => {
        console.log('修改头像')
        showPhoto()
    };
    const dispatch = useDispatch()
    const handleMenuClick = (e) => {
        if (e.key === '1') {
            console.log('修改密码')
            showUpdate()
        }
        if (e.key === '2') {
            console.log('修改信息', userInfo)
            showRegister(userInfo)
        }
        if (e.key === '3') {
            console.log('退出登录')
            dispatch.user.setToken('');
            dispatch.user.setUserInfo({});
            Cookies.removeItem('loginMsg');
        }
    };
    const items = [
        {
            label: '修改密码',
            key: '1'
        },
        {
            label: '修改信息',
            key: '2'
        },
        {
            label: '退出登录',
            key: '3'
        }
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <div>
            {isLoggedIn ? (
                <Dropdown.Button menu={menuProps} onClick={handleButtonClick} icon={<DownOutlined/>}
                                 className='dropdown'>
                    <Avatar shape="square" src={userInfo.head_photo}/>
                    <span>{userInfo.real_name}</span>
                </Dropdown.Button>
            ) : (
                <div className="user-login">
                    <Button onClick={showLogin} style={{marginRight: '10px'}}>登录</Button>
                    <Button onClick={showRegister}>注册</Button>
                </div>
            )}
            {loginModal}
            {registerModal}
            {updateModal}
            {photoModal}
        </div>
    );
};

export default HeaderRight;