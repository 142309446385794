import React, {useState, useEffect} from 'react';
import {Editor, Toolbar} from '@wangeditor/editor-for-react';
import '@wangeditor/editor/dist/css/style.css';
import './RichEditor.scss'

const RichTextEditor = ({
                            height = 500,
                            maxLength = 1000,
                            value = '',
                            onSubmit = () => {
                            },
                            onChange = () => {
                            }
                        }) => {
    const [editor, setEditor] = useState(null);
    const [currentLength, setCurrentLength] = useState(0);
    const [htmlContent, setHtmlContent] = useState('');

    // 工具栏配置
    const toolbarConfig = {
        excludeKeys: ['fullScreen'],
        toolbarKeys: [
            'bold', 'italic', 'underline', 'color', 'fontSize', 'clearStyle',
            '|', 'bulletedList', 'numberedList', 'todo',
            '|', 'emotion', 'insertLink', 'insertImage', 'insertTable',
            '|', 'undo', 'redo'
        ]
    };

    // 编辑器配置
    const editorConfig = {
        placeholder: '请输入内容...',
        maxLength,
        onCreated: (editor) => {
            setCurrentLength(editor.getText().replace(/\n/g, '').length);
        },
        onChange: (editor) => {
            const text = editor.getText().replace(/\n/g, '');
            const html = editor.getHtml();
            setCurrentLength(text.length);
            setHtmlContent(html);
            onChange(html);
        },
        MENU_CONF: {
            uploadImage: {
                server: '/api/upload',
                fieldName: 'image'
            },
            emotion: {
                emotions: '😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉 😌 😍 🥰 😘 😗 😙 😚 😋 😛 😝 😜 🤪 🤨 🧐 🤓 😎'.split(' ')
            }
        }
    };

    const handleSubmit = () => {
        if (currentLength > maxLength) return;
        onSubmit(htmlContent);
    };

    useEffect(() => {
        return () => {
            if (editor) editor.destroy();
        };
    }, [editor]);
    return (
        <div className="rich-text-editor">
            <div className="top">
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    className='toolbar'
                />
                <button
                    className='button'
                    onClick={handleSubmit}
                    style={{

                        opacity: currentLength > maxLength ? 0.5 : 1
                    }}
                    disabled={currentLength > maxLength}
                >
                    提交
                </button>
            </div>
            <Editor
                defaultConfig={editorConfig}
                value={value}
                onCreated={setEditor}
                mode="default"
                style={{height: height - 90}}
            />

        </div>
    );
};
export default RichTextEditor;
