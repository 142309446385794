import React, { forwardRef,  useImperativeHandle, useState } from 'react';
import CommonModal from "@/computed/CommonModal";
import { Form, Input, message } from 'antd';
import { useRef } from 'react';
import md5 from 'js-md5';
import httpRequest from "@/utils/httpRequest";
import { useSelector} from "react-redux";

const ChangePasswordModal = forwardRef((props, ref) => {
    const { onCancel } = props;
    const userInfo = useSelector((state) => state.user.userInfo);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);

    // 暴露 openModal 方法
    useImperativeHandle(ref, () => ({
        openModal: () => {
            modalRef.current?.open();
            form.resetFields(); // 打开弹框时重置表单
        },
    }));

    // 提交表单
    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const params = {
                user_name: userInfo.user_name, // 从 userInfo 中获取用户名
                pass: values.password, // 新密码
                password: values.pass, // 原密码
                passcode: md5(values.password), // 原密码的 MD5 值
            };
            setLoading(true);
            try {
                const res = await httpRequest.post('/password', params);
                if (res.code === 200) {
                    message.success(res.msg);
                    modalRef.current?.close();
                } else {
                    message.warning(res.msg);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error('表单验证失败:', err);
        }
    };

    return (
        <CommonModal
            ref={modalRef}
            title="修改密码"
            loading={loading}
            onCancel={onCancel}
            onOk={handleSubmit}
        >
            <Form form={form} labelCol={{ span: 6 }}>
                {/* 用户名 */}
                <Form.Item label="用户名" name="user_name" initialValue={userInfo.user_name}>
                    <Input disabled />
                </Form.Item>

                {/* 原密码 */}
                <Form.Item
                    label="原密码"
                    name="pass"
                    rules={[{ required: true, message: '请输入原密码' }]}
                >
                    <Input.Password placeholder="请输入原密码" />
                </Form.Item>

                {/* 新密码 */}
                <Form.Item
                    label="新密码"
                    name="password"
                    rules={[
                        { required: true, message: '请输入新密码' },
                        { pattern: /^[a-zA-Z]\w{7,15}$/, message: '字母开头,由数字字母下划线组成,长度8-16位' },
                    ]}
                >
                    <Input.Password placeholder="请输入新密码" />
                </Form.Item>

                {/* 确认密码 */}
                <Form.Item
                    label="确认密码"
                    name="checkPass"
                    dependencies={['password']} // 依赖新密码字段
                    rules={[
                        { required: true, message: '请确认新密码' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不一致'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="请确认新密码" />
                </Form.Item>
            </Form>
        </CommonModal>
    );
});

export default ChangePasswordModal;
