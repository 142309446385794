import React, {useEffect, useState} from 'react';
import './MsgView.scss'
import httpRequest from "@/utils/httpRequest";
import {AliwangwangOutlined, DownOutlined, LikeOutlined, SendOutlined, UpOutlined} from "@ant-design/icons";
import moment from "moment";
import {message, Pagination} from "antd";
import RichEditor from '@/computed/RichEditor';
import {useDispatch, useSelector} from "react-redux";

const MsgView = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    console.log('userInfo',userInfo)
    // 编辑器
    const [content, setContent] = useState('');
    const handleSubmit = (value) => {
        console.log('提交内容:', value);
        if (value === '<p><br></p>') {
            message.warning('请输入内容')
            return
        }
        httpRequest.post('/message/add', {
            user_id: userInfo.id,
            user_name: userInfo.real_name,
            content: value
        })
            .then(res => {
                console.log(res)
                if (res.code === 200) {
                    console.log(555)
                    getMsgList({...formData});
                }
            });
        // 这里添加提交逻辑
        setContent(''); // 清空编辑器
    };
    const [formData, setFormData] = useState({
        limit: 10,
        page: 1,
        total: 0,
    });
    const [msgList, setMsgList] = useState([])
    const getMsgList = params => {
        try {
            httpRequest.get('/message', params)
                .then(res => {
                    setMsgList(res.data.list);
                    setFormData(r => ({
                        ...r,
                        total: res.data.total,
                    }))
                });
        } catch (error) {
            console.error('获取题目失败:', error);
        }

    }
    // 处理分页变化（简化版）
    const handlePageChange = (page) => {
        setFormData(prev => ({...prev, page}));
    };
    // 留言点赞
    const messageLikeIdList = userInfo?.message_like_id || []
    const commentLikeIdList = userInfo?.comment_like_id||[]
    console.log('messageLikeIdList',messageLikeIdList)
    const messageLike = (item) => {
        console.log('item', item)
        const id = item.id;
        const isLiked = messageLikeIdList.includes(id);
        let newLikeCount = item.like || 0;
        let newLikeIds = [...messageLikeIdList];
        console.log('newLikeIds',newLikeIds)
        if (isLiked) {
            console.log('取消')
            newLikeCount--;
            newLikeIds = newLikeIds.filter(t => t !== id);
        } else {
            console.log('点赞')
            newLikeCount++;
            newLikeIds = [id, ...newLikeIds];
        }
        console.log('ne',newLikeIds)
        // 发送 API 请求
        httpRequest.post('/message/like', {
            type: isLiked ? 0 : 1,
            id,
            message_like_id: JSON.stringify(newLikeIds),
            user_id: userInfo?.id
        }).then(res => {
            console.log('API response:', res);
            if(res.code===200){
                // 更新点赞状态
                // 更新 Redux 中的用户信息
                const updatedUserInfo = {
                    ...userInfo,
                    message_like_id: newLikeIds
                };
                console.log('updatedUserInfo',updatedUserInfo)
                dispatch.user.setUserInfo(updatedUserInfo)
                // getMsgList({...formData});
            }
        });
    }
    // 评论留言
    const [showReplyId, setShowReplyId] = useState(-1);
    const [showCommentList, setShowCommentList] = useState([]);
    const [commentInfo, setCommentInfo] = useState({})
    const [commentData, setCommentData] = useState({})

    const toComment = (index, item, item1) => {
        setCommentInfo({
            for_id: item1 ? item1.user_id : item.user_id,
            parent_id: item.id,
            user_id: userInfo.id,
            user_name: userInfo.real_name,
            for_name: item1 ? item1.real_name : item.real_name,
            type: 'message'
        })
        // this.$set(this.commentInfo,'for_name',item1?item1.real_name:item.real_name)
        setShowReplyId(showReplyId === item.id ? -1 : item.id)
    }
    // const commentLike = () => {
    //     console.log(666)
    // }
    const commentLike = async (item) => {
        try {
            const id = item.id;
            const isLiked = commentLikeIdList.includes(id);
            // const newLikeCount = isLiked ? (item.like || 0) - 1 : (item.like || 0) + 1;
            console.log('commentLikeIdList',commentLikeIdList)
            // 生成新点赞ID数组‌:ml-citation{ref="2,5" data="citationList"}
            const newLikeIds = isLiked
                ? commentLikeIdList.filter(t => t !== id)
                : [...commentLikeIdList, id];
            console.log('生成新点赞ID数组‌',newLikeIds)
            // 立即更新本地状态（乐观更新）‌:ml-citation{ref="4,7" data="citationList"}
            const tempUserInfo = {
                ...userInfo,
                comment_like_id: newLikeIds
            };
            dispatch.user.setUserInfo(tempUserInfo)

            // 发送API请求‌:ml-citation{ref="3,8" data="citationList"}
            const res = await httpRequest.post('/comment/like', {
                type: isLiked ? 0 : 1,
                id,
                comment_like_id: JSON.stringify(newLikeIds),
                user_id: userInfo.id
            });
            console.log('commentLike',res)
            if (res.code !== 200) {
                // 请求失败时回滚状态‌:ml-citation{ref="2,5" data="citationList"}
                dispatch.user.setUserInfo({
                    ...userInfo,
                    comment_like_id: commentLikeIdList
                })
                return;
            }

        } catch (error) {
            console.error('点赞操作失败:', error);
            // 恢复原始状态‌:ml-citation{ref="4,7" data="citationList"}
            dispatch.user.setUserInfo({
                ...userInfo,
                comment_like_id: JSON.stringify(commentLikeIdList)
            })
        }
    };

    // const submitComment = value => {
    //     console.log('提交内容:', value);
    //     console.log('提交内容:', value.trim());
    //     console.log('提交内容:', !value.trim());
    //     console.log('提交内容:', !!value.trim());
    //     if (value === '<p><br></p>') {
    //         message.warning('请输入内容')
    //         return
    //     }
    //     httpRequest.post('/comment/add', commentInfo).then(res => {
    //         console.log(res)
    //         if (res.code === 200) {
    //             setShowReplyId(-1)
    //             getMsgList({...formData});
    //         } else {
    //             message.warning(res.msg)
    //         }
    //     });
    // }
    const submitComment = async (value) => {
        if (value === '<p><br></p>' || !value.trim()) {
            message.warning('请输入有效内容');
            return;
        }

        try {
            const res = await httpRequest.post('/comment/add', commentInfo);

            if (res.code === 200) {
                // 更新本地状态
                setCommentData(prev => {
                    const targetComment = prev[showReplyId] || { list: [] };

                    return {
                        ...prev,
                        [showReplyId]: {
                            ...targetComment,
                            list: [...res.list, ...targetComment.list] // 新评论插入列表头部‌:ml-citation{ref="5" data="citationList"}
                        }
                    };
                });

                // 重置交互状态
                setShowReplyId(-1);
                // 提交成功后增加评论总数
                setMsgList(msgList.map(t=>{
                    if(t.id===showReplyId){
                        t.comment_count++
                    }
                    return t;
                }))
                // setCommentData(prev => ({
                //     ...prev,
                //     [showReplyId]: {
                //         ...prev[showReplyId],
                //         total: prev[showReplyId].total + 1
                //     }
                // }));

                message.success('回复成功');
            } else {
                message.warning(res.msg);
            }
        } catch (error) {
            console.error('提交失败:', error);
            message.error('提交失败，请检查网络');
        }
    };

    // const getMoreComment = ()=>{
    //     console.log(666)
    // }
    // const getComment = item=>{
    //     console.log('item.id',item.id)
    //     if(showCommentList.includes(item.id)){
    //         console.log(1)
    //         let arr = showCommentList.filter(t=>t!==item.id)
    //         console.log(arr)
    //         setShowCommentList(arr)
    //     }else{
    //         console.log(2)
    //         setShowCommentList([...showCommentList,item.id])
    //
    //     }
    // }

    const getComment = async (item) => {
        if (!commentData[item.id]?.list) {
            try {
                const res = await httpRequest.get('/comment', {
                    id: item.id,
                    limit: 3,
                    offset:0
                })
                console.log('resresres',res)
                if(res.code===200){
                    // 逻辑1：切换显示状态
                    setShowCommentList(prev =>
                        prev.includes(item.id)
                            ? prev.filter(id => id !== item.id)  // 隐藏评论‌:ml-citation{ref="7" data="citationList"}
                            : [...prev, item.id]                 // 显示评论‌:ml-citation{ref="1" data="citationList"}
                    )
                    setCommentData(prev => ({
                        ...prev,
                        [item.id]: {
                            list: res.data.list,
                            offset: 0
                        }
                    }))
                }
            } catch (error) {
                console.error('获取评论失败:', error)
            }
        }
    }

// 加载更多评论‌:ml-citation{ref="5" data="citationList"}
    const getMoreComment = async (item) => {
        const offset = commentData[item.id].offset + 3

        const res = await httpRequest.get('/comment', {
            id: item.id,
            limit: 3,
            offset
        })

        setCommentData(prev => ({
            ...prev,
            [item.id]: {
                list: [...prev[item.id].list, ...res.data.list],
                offset
            }
        }))
    }

    // 收起评论‌:ml-citation{ref="7" data="citationList"}
    const setItemCommentShow = (itemId) => {
        setShowCommentList(prev =>
            prev.filter(id => id !== itemId)
        )
    }


    // 初始化加载类型列表和数据列表
    useEffect(() => {
        getMsgList({...formData});
    }, [formData.page]);

    return (
        <div className='msg-page'>
            <div className="title">
                <h2>
                    <strong>寄语</strong>
                    :愿作明灯照前路，与君共勉探前端
                </h2>
                <h3>网站初创有什么问题或者BUG希望大家提供宝贵意见</h3>
            </div>
            <div className="mes-box">
                <div className="msg-send">
                    <RichEditor
                        value={content}
                        onChange={setContent}
                        height={190}
                        maxLength={500}
                        onSubmit={handleSubmit}
                        onExceed={() => console.log('超过字数限制')}
                    />

                </div>
                <div className="msg-content">
                    {msgList.map((item, index) => (
                        <div className="msg-c-item" key={item.id}>
                            <img src={item.head_photo} alt=""/>
                            <div className="msg-c-main">
                                {/*顶部*/}
                                <div className="top">
                                    <div>
                                        <span className="name">{item.real_name}</span>
                                        <span
                                            className="floor">第{formData.total - (formData.page - 1) * 10 - index}楼</span>
                                    </div>
                                    {/*留言内容*/}
                                    <p className='content' dangerouslySetInnerHTML={{__html: item.content}}></p>
                                </div>
                                {/*留言下方*/}
                                <div className="bottom">
                                    <span
                                        className="time">{moment(item.create_date).format('YYYY-MM-DD HH:mm:ss')}</span>
                                    <p>
                                        <span className={showReplyId === item.id ? 'reply on' : 'reply'}
                                              onClick={() => toComment(index, item)}>
                                            <AliwangwangOutlined/>回复
                                        </span>
                                        <span className="comment"
                                              onClick={() => getComment(item)}
                                        >
                                            <SendOutlined/>评论{item.comment_count}</span>
                                        <span className={messageLikeIdList.includes(item.id) ? 'like on' : 'like'}
                                              onClick={() => messageLike(item)}
                                        >
                                            <LikeOutlined/>
                                            点赞{item.like || 0}
                                        </span>
                                    </p>
                                </div>
                                {/* 评论区域 */}
                                {/* 评论区域 */}
                                {showCommentList.includes(item.id) && (
                                    <div className="comment">
                                        {/* 现有评论列表 */}
                                        {commentData[item.id]?.list.map(item1 => (
                                            <div key={item1.id} className="comment-item">
                                                <img src={item1.head_photo} alt=""/>
                                                <div className="c-i-right">
                                                    <div className="com-top">
                                                        <span>{item1.user_name}</span>
                                                        <b>回复</b>
                                                        <span>{item1.for_name}</span>
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{__html: item1.content}}/>
                                                    <div className="bottom">
                                                        <span className="time">
                                                          {moment(item1.create_date).format('YYYY-MM-DD HH:mm:ss')}
                                                        </span>
                                                        <p>
                                                          <span className="reply"
                                                                onClick={() => toComment(index, item, item1)}>
                                                            <i className="el-icon-chat-round"></i>
                                                            回复
                                                          </span>
                                                            <span
                                                                className={`like ${commentLikeIdList.includes(item1.id) ? 'on' : ''}`}
                                                                onClick={() => commentLike(item1)}
                                                            >
                                                            <i className="el-icon-thumb"></i>
                                                            点赞{item1.like || 0}
                                                          </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {/* 分页控制 */}
                                        {item.comment_count > commentData[item.id]?.list.length && (
                                            <button className='get-more' onClick={() => getMoreComment(item)}>
                                                <DownOutlined /><span>展开更多（剩余 {item.comment_count - commentData[item.id].list.length} 条）</span>
                                            </button>
                                        )}

                                        {/* 收起按钮 */}
                                        <button className='hidden-comment' onClick={() => setItemCommentShow(item.id)}>
                                            <UpOutlined /><span>收起评论</span>
                                        </button>
                                    </div>
                                )}

                                {/*{showCommentList.includes(item.id) && (*/}
                                {/*    <div className="comment">*/}
                                {/*        <div>999999</div>*/}
                                {/*        {item.comment_list?.map(item1 => (*/}
                                {/*            <div key={item1.id} className="comment-item">*/}
                                {/*                <img src={item1.head_photo} alt=""/>*/}
                                {/*                <div className="c-i-right">*/}
                                {/*                    <div className="com-top">*/}
                                {/*                        <span>{item1.user_name}</span>*/}
                                {/*                        <b>回复</b>*/}
                                {/*                        <span>{item1.for_name}</span>*/}
                                {/*                    </div>*/}
                                {/*                    <div dangerouslySetInnerHTML={{__html: item1.content}}/>*/}
                                {/*                    <div className="bottom">*/}
                                {/*                        <span className="time">*/}
                                {/*                          {moment(item1.create_date).format('YYYY-MM-DD HH:mm:ss')}*/}
                                {/*                        </span>*/}
                                {/*                        <p>*/}
                                {/*                          <span className="reply"*/}
                                {/*                                onClick={() => toComment(index, item, item1)}>*/}
                                {/*                            <i className="el-icon-chat-round"></i>*/}
                                {/*                            回复*/}
                                {/*                          </span>*/}
                                {/*                            <span*/}
                                {/*                                className={`like ${commentLikeIdList.includes(item1.id) ? 'on' : ''}`}*/}
                                {/*                                onClick={() => commentLike(item1)}*/}
                                {/*                            >*/}
                                {/*                            <i className="el-icon-thumb"></i>*/}
                                {/*                            点赞{item1.like || 0}*/}
                                {/*                          </span>*/}
                                {/*                        </p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        ))}*/}
                                {/*        /!* 更多评论 *!/*/}
                                {/*        {item.comment_list && (*/}
                                {/*            <p className="more">*/}
                                {/*                {item.comment_list.length < item.comment_count ? (*/}
                                {/*                    <span onClick={() => getMoreComment(item)}>*/}
                                {/*                    展开更多*/}
                                {/*                    <i className="el-icon-arrow-down"></i>*/}
                                {/*                  </span>*/}
                                {/*                ) : (*/}
                                {/*                    <span onClick={() => setItemCommentShow(item, false)}>*/}
                                {/*                    收起*/}
                                {/*                    <i className="el-icon-arrow-up"></i>*/}
                                {/*                  </span>*/}
                                {/*                )}*/}
                                {/*            </p>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {/* 评论文本框 */}
                                {showReplyId === item.id && (
                                    <div className="reply-box">
                                        <p>回复@{commentInfo?.for_name}</p>
                                        <RichEditor
                                            value={commentInfo?.content}
                                            onChange={v => setCommentInfo(prev => ({...prev, content: v}))}
                                            maxLength={100}
                                            style={{height: 100}}
                                            height={190}
                                            maxLength={500}
                                            onSubmit={submitComment}
                                            onExceed={() => console.log('超过字数限制')}
                                        />
                                    </div>
                                )}

                            </div>
                        </div>
                    ))}
                </div>
                <Pagination
                    current={formData.page}
                    total={formData.total}
                    pageSize={formData.limit}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    showTotal={total => `共 ${total} 题`}
                    className="custom-pagination"
                />
            </div>
        </div>
    );
};

export default MsgView;