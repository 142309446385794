import axios from 'axios'
import {message} from "antd";

const baseURL = process.env.NODE_ENV !== 'production'?'/api':''
// const baseURL ='http://localhost:3002'
const http = axios.create({
	baseURL,
	timeout: 10000,
	headers: {
		'X-Custom-Header': 'foobar'
	}
});

// 添加请求拦截器
http.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	let token = sessionStorage.getItem('token');
	if (token) {
		// config.headers['authorization'] = token
		config.headers['authorization'] = 'Bearer ' + token;
	}
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function(response) {
	// 2xx 范围内的状态码都会触发该函数。
	// 对响应数据做点什么
	if(response.data.code===401){
		message.warning('请先登录')
	}
	// response.data.status = response.status

	return response.data;
}, function(error) {
	// 超出 2xx 范围的状态码都会触发该函数。
	// 对响应错误做点什么
	return Promise.reject(error);
});

let httpRequest = {
	get(url,params){
		return http.get(url,{params})
	},
	post(url,params){
		return http.post(url,params)
	}
}

export default httpRequest
