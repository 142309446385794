import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies, decryptBy, encryptBy } from "@/utils";
import md5 from "js-md5";
import httpRequest from "@/utils/httpRequest";

export const useAutoLogin = () => {
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(!!token);
    useEffect(() => {
        setIsLoggedIn(!!token);
        const handleAutoLogin = async () => {
            try {
                // ✅ 修复 1: 修正括号不匹配问题
                const loginMsgStr = decryptBy(Cookies.getItem('loginMsg')) || '{}';
                // ✅ 修复 2: 使用默认空对象字符串 '{}' 而不是空对象 {}
                const loginMsg = JSON.parse(loginMsgStr);

                if (loginMsg.isAuto) {
                    const res = await httpRequest.post('/login', {
                        user_name: loginMsg.user_name,
                        password: md5(loginMsg.password)
                    });

                    if (res.code === 200) {
                        dispatch.user.setToken(res.data.token);
                        dispatch.user.setUserInfo(res.data.user);
                        Cookies.setItem('loginMsg', encryptBy(JSON.stringify(loginMsg)), 30);
                        setIsLoggedIn(true);
                    }
                }
            } catch (error) {
                setIsLoggedIn(false);
            }
        };

        if (!token) handleAutoLogin();
    }, [token, dispatch]);

    return { isLoggedIn };
};